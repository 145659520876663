const storageKey = 'theme-preference';

const onClick = () => {
  theme.value = theme.value === 'light' ? 'dark' : 'light';

  setPreferredTheme();
}

const getPreferredTheme = () => {
  if (localStorage.getItem(storageKey)) {
    return localStorage.getItem(storageKey);
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
}

const setPreferredTheme = () => {
  localStorage.setItem(storageKey, theme.value);
  reflectPreference();
}

const reflectPreference = () => {
  document.firstElementChild.setAttribute('data-theme', theme.value);

  document.querySelector('#theme-toggle')?.setAttribute('aria-label', theme.value);
}

const changeThemeEvent = ({matches: isDark}) => {
  theme.value = isDark ? 'dark' : 'light'
  setPreferredTheme()
};

const theme = {
  value: getPreferredTheme(),
}

reflectPreference();

window.onload = () => {
  reflectPreference();
  document.querySelector('#theme-toggle').addEventListener('click', onClick);
}


window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', changeThemeEvent);
